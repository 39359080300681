import barba from "@barba/core";
// import barbaRouter from '@barba/router';
import { gsap } from "gsap/all";
import { initAnimations, killAnimations } from "./animations";

import splitting from "splitting";
import { initMisc } from "./misc";

// barba.use(barbaRouter, {
//   routes: [
//     { name: 'self', path: '/index' },
//     { name: 'self', path: '/about' },
//     { name: 'self', path: '/contact' },
//     // { name: 'home', path: '(/|/index.html)' },
//     // { name: 'about', path: '(/|/about.html)' },
//     // { name: 'contact', path: '(/|/contact.html)' },
//   ],
// })

barba.init({
  debug: false,
  // preventRunning: true,
  transitions: [
    {
      beforeOnce({ next }) {
        // console.log("beforeONCE");
        // console.log(next);
        return gsap.from(next.container, {
          // autoAlpha: 0,
          // duration: 1.1,
          // clearProps: "all",
          // ease: "none",
        });
      },
      once({ next }) {
        if (next.namespace === "about") {
          let aboutHeight = parseInt(
            getComputedStyle(document.querySelector(".about-section")).height,
            10
          );
          if (aboutHeight < window.innerHeight) {
            document.body.style.overflow = "hidden";
          } else {
            document.body.style.overflow = "unset";
            document.body.style.height = `${aboutHeight}px`;
            document.querySelector(
              ".svg-container"
            ).style.height = `${aboutHeight}px`;
            document.querySelector("main").style.height = `${aboutHeight}px`;
            // document.querySelector("main").style.height = `${aboutHeight + 5}px`;
          }
        }
        // console.log("once");
        return gsap.from(next.container, {
          // autoAlpha: 0,
          duration: 1.1,
          // clearProps: "all",
          ease: "none",
        });
      },
      leave({ current }) {
        // console.log("leave");
        return gsap.to(current.container, {
          autoAlpha: 0,
          duration: 0.7,
          ease: "none",
        });
      },
      enter({ next }) {
        // console.log("enter");
        if (next.namespace === "home") {
          document.querySelector(".svg-container").style.height =
            "calc(310vh + 150px)";
        }
        if (next.namespace === "about") {
          let aboutHeight = parseInt(
            getComputedStyle(document.querySelector(".about-section")).height,
            10
          );
          if (aboutHeight < window.innerHeight) {
            document.body.style.overflow = "hidden";
          } else {
            document.body.style.overflow = "unset";
            // document.body.style.height = `${aboutHeight}px`;
            document.querySelector(
              ".svg-container"
            ).style.height = `${aboutHeight}px`;
            document.querySelector("main").style.height = `${aboutHeight}px`;
          }
        }
        return gsap.from(next.container, {
          autoAlpha: 0,
          duration: 0.7,
          // clearProps: "all",
          ease: "none",
        });
      },
      afterLeave() {},
      afterEnter() {},
      after(data) {
        // console.log("after");
        if (data.next.namespace === "about") {
          let aboutHeight = parseInt(
            getComputedStyle(document.querySelector(".about-section")).height,
            10
          );
          if (aboutHeight < window.innerHeight) {
            document.body.style.overflow = "hidden";
          } else {
            document.body.style.overflow = "unset";
            // document.body.style.height = `${aboutHeight}px`;
            document.querySelector(
              ".svg-container"
            ).style.height = `${aboutHeight}px`;
            document.querySelector("main").style.height = `${aboutHeight}px`;
            // document.querySelector("main").style.height = `${aboutHeight + 5}px`;
          }
        }
      },
    },
    {
      name: "self",
      after() {
        initMisc();
        initAnimations();
      },
    },
  ],
  views: [
    {
      namespace: "home",
      beforeLeave() {},
      beforeEnter() {
        // initBlobs();
        splitting();
        initAnimations();

        // update the menu based on user navigation
        document.body.style.overflow = "unset";
      },
      afterEnter() {
        initMisc();
        document.querySelector(".home-link").classList.add("current-link");
      },
    },
    {
      namespace: "about",
      beforeEnter() {
        scrollTo(0, 0);
        document.body.style.overflow = "unset";
      },
      afterEnter() {
        document.querySelector(".about-link").classList.add("current-link");
        killAnimations();
      },
    },
    {
      namespace: "contact",
      beforeEnter() {
        scrollTo(0, 0);
        document.body.style.overflow = "hidden";
      },
      afterEnter() {
        document.querySelector(".contact-link").classList.add("current-link");
        killAnimations();
      },
    },
  ],
});
